import Mock from "../mock";

const database = {
  information: {
    name: 'Rizky',
    aboutContent: "I am a Test Engineer, who also passionate software development. I was a scientific assistant for 4 years in Machine Learning Area. I am developing and maintaining Machine Learning Library. I have a quick grasp and constantly learning new things.",
    age: 28,
    phone: '',
    nationality: 'Indonesia',
    language: 'Bahasa Indonesia, English, German',
    email: 'personal@rizkydiprasetya.com',
    address: '',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/mochammad-rizky-diprasetya/',
      dribbble: '',
      github: 'https://github.com/rizkydiprasetya'
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image-small.jpg',
    aboutImageLg: '/images/about-image-large.jpg',
    cvfile: '/files/resume.pdf'
  },
  services: [
  ],
  reviews: [
  ],
  skills: [
    {
      title: "Python",
      value: 90
    },
    {
      title: "Object Oriented Programming/UML",
      value: 90
    },
    {
      title: "Robot Operating System (ROS)",
      value: 85
    },
    {
      title: "PyTorch/Numpy/Matplotlib/Pandas",
      value: 85
    },
    {
      title: "Git",
      value: 85
    },
    {
      title: "Linux",
      value: 85
    },
    {
      title: "CI/CD",
      value: 85
    },
    {
      title: "RaspberryPi/Revolution Pi/Jetson Nano",
      value: 85
    },
    {
      title: "Autocad/Blender",
      value: 75
    },
    {
      title: "Docker",
      value: 70
    },
    {
      title: "SQL",
      value: 70
    },
    {
      title: "Socket/MQTT/OPCUA",
      value: 70
    },
    {
      title: "I2C/RS232/SPI",
      value: 70
    },
    {
      title: "Teensy/Arduino/ESP",
      value: 65
    },
    {
      title: "MATLAB/Simulink",
      value: 60
    },
    {
      title: "C/C++",
      value: 60
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "MLPro",
      subtitle: "The Integrative Middleware Framework for Standardized Machine Learning in Python.",
      imageUrl: "/images/mlpro-small.jpg",
      largeImageUrl: ["/images/mlpro-large.jpg"],
      url: 'https://github.com/fhswf/MLPro'
    },
    {
      id: 2,
      title: "ABB Robot Welding",
      subtitle: "Integration of ROS for ABB Robot with MoveIt as the motion planning framework.",
      imageUrl: "/images/abb_duo_small.gif",
      largeImageUrl: ["/images/abb_duo_small.gif"],
      url: ''
    },
    {
      id: 3,
      title: "Condition Monitoring",
      subtitle: "Condition monitoring for pump machine with Revolution Pi.",
      imageUrl: "/images/anzatech-small.jpg",
      largeImageUrl: ["/images/anzatech-large.jpg"],
      url: 'https://transferverbund-sw.de/mehr-transparenz-fuer-die-pumpe/'
    },
  ],
  publications: [
    {
      id: 1,
      title: "Integration of ABB Robot Manipulators and Robot Operating System for Industrial Automation",
      year: "Accepted 2023",
      author: "M. R. Diprasetya, S. Yuwono, M. Löppenberg and A. Schwung",
      abstract: "The integration of advanced robotic technologies with modern software platforms has enabled significant advancements in industrial automation. This paper aims to introduce a novel workflow to integrate ABB industrial robot manipulators and the Robot Operating System (ROS), where communication is built through OPC Unified Architecture or a virtual controller with EGM+RWS. Moreover, we also provide a method to deploy the ROS environment using container technology in our workflow. The developed workflow was tested and evaluated in a real-world welding application, demonstrating its capability to automate the welding process of steel looped hooks for supermarket shelves with high accuracy and efficiency, in which the path planner for the robot is obtained from MoveIt by ROS. The introduced workflow can also be integrated with the MLPro framework, which enriches the workflow with machine learning-related algorithms, e.g. for path planning. The results of this study demonstrate the potential of integrating ABB industrial robot manipulators with ROS for industrial process automation and highlight the benefits of using ROS as a software platform for industrial robotics applications.",
      url: 'https://www.researchgate.net/publication/369927628_Integration_of_ABB_Robot_Manipulators_and_Robot_Operating_System_for_Industrial_Automation'
    },
    {
      id: 2,
      title: "MLPro-MPPS—A high-performance simulation framework for customizable production systems",
      year: "May 2023",
      author: "S. Yuwono, M. Löppenberg, D. Arend, M. R. Diprasetya and A. Schwung",
      abstract: "Although the integration of machine learning into production systems has demonstrated significant potential, its real-life applications remain challenging. It is often necessary to rely on digital representations of the actual systems to design and test machine learning algorithms for controlling the systems because conducting such processes directly in real systems is expensive and risky. Hence, in this paper, we present a standardized, multi-purpose and flexible production systems simulator in Python for scientific and industrial activities, namely MLPro-MPPS, that is integrated with the MLPro package. Consequently, this allows the simulations by MLPro-MPPS to be compatible with machine learning tasks.",
      url: 'https://www.softwareimpacts.com/article/S2665-9638(23)00046-5/fulltext'
    },
    {
      id: 3,
      title: "MLPro 1.0 - Standardized reinforcement learning and game theory in Python",
      year: "2022",
      author: "D. Arend, S. Yuwono, M. R. Diprasetya and A. Schwung",
      abstract: "Nowadays there are numerous powerful software packages available for most areas of machine learning (ML). These can be roughly divided into frameworks that solve detailed aspects of ML and those that pursue holistic approaches for one or two learning paradigms. For the implementation of own ML applications, several packages often have to be involved and integrated through individual coding. The latter aspect in particular makes it difficult for newcomers to get started. It also makes a comparison with other works difficult, if not impossible. Especially in the area of reinforcement learning (RL), there is a lack of frameworks that fully implement the current concepts up to multi-agents (MARL) and model-based agents (MBRL). For the related field of game theory (GT), there are hardly any packages available that aim to solve real-world applications. Here we would like to make a contribution and propose the new framework MLPro, which is designed for the holistic realization of hybrid ML applications across all learning paradigms. This is made possible by an additional base layer in which the fundamentals of ML (interaction, adaptation, training, hyperparameter optimization) are defined on an abstract level. In contrast, concrete learning paradigms are implemented in higher sub-frameworks that build on the conventions of this additional base layer. This ensures a high degree of standardization and functional recombinability. Proven concepts and algorithms of existing frameworks can still be used. The first version of MLPro includes sub-frameworks for RL and cooperative GT.",
      url: 'https://www.sciencedirect.com/science/article/pii/S2666827022000482'
    },
    {
      id: 4,
      title: "MLPro–An integrative middleware framework for standardized machine learning tasks in Python",
      year: "2022",
      author: "D. Arend, M. R. Diprasetya, S. Yuwono and A. Schwung",
      abstract: "In recent years, many powerful software packages have been released on various aspects of machine learning (ML). However, there is still a lack of holistic development environments for the standardized creation of ML applications. The current practice is that researchers, developers, engineers and students have to piece together functionalities from several packages in their own applications. This prompted us to develop the integrative middleware framework MLPro that embeds flexible and recombinable ML models into standardized processes for training and real operations. In addition, it integrates numerous common open source frameworks and thus standardizes their use. A meticulously designed architecture combined with a powerful foundation of overarching basic functionalities ensures maximum recombinability and extensibility. In the first version of MLPro, we provide sub-frameworks for reinforcement learning (RL) and game theory (GT).",
      url: 'https://www.softwareimpacts.com/article/S2665-9638(22)00105-1/fulltext'
    },
    {
      id: 5,
      title: "Homogeneous Transformation Matrix Based Neural Network for Model Based Reinforcement Learning on Robot Manipulator",
      year: "2022",
      author: "M. R. Diprasetya and A. Schwung",
      abstract: "This paper introduces a novel architecture of neural networks based on a homogeneous transformation matrix for model-based reinforcement learning in robotics. It shows how the homogeneous transformation neural network outperforms the standard feed-forward neural network in the robot manipulator area. The homogeneous transformation matrix can be an alternative for modeling robotic manipulators as a neural network. Since, there are a lot of differences in the robotic manipulator model, especially in geometry, a geometry transfer is introduced in this paper. It shows how to transfer the feature to the other homogeneous transformation matrix for a different type of geometry of the robot manipulator.",
      url: 'https://ieeexplore.ieee.org/document/10002834'
    },
    {
      id: 6,
      title: "Monitoring and Forecasting of Air Emissions with IoT Measuring Stations and a SaaS Cloud Application",
      year: "2020",
      author: "F. A. N., M. T. Ibrahim, R. M. Diprasetya, O. O. Flores and A. Schwung",
      abstract: "Environmental pollution is a significant problem in densely populated cities due to increased respiratory diseases in the population. The pollution level is used to inform the public to take extraordinary measures based on a pollution scale. Particulate matter (PM), precisely PM10 and PM2.5 , is used to estimate the degree of pollution. The monitoring and analysis of these two variables have attracted the research community's attention, particularly in the design of measuring stations, forecasting, and software infrastructure to host user applications. This paper proposes a modular and ready-to-use architecture for a SaaS cloud application for air emissions forecasting using IoT measuring stations. The SaaS cloud application is connected to the IoT measuring stations located in the densely populated cities of El Salvador. The data is used to create deep learning models, to make forecasts for the next day.",
      url: 'https://ieeexplore.ieee.org/document/9507127'
    },
    {
      id: 7,
      title: "Fault Detection Assessment using an extended FMEA and a Rule-based Expert System",
      year: "2019",
      author: "F. Arévalo, C. Tito, M. R. Diprasetya and A. Schwung",
      abstract: "Early fault detection and diagnosis lead to enhanced production performance since the companies can avoid unplanned machines downtimes and final product quality issues. Expert systems are widely popular in the industry for fault detection and diagnosis applications. This is due to its easy implementation, and its versatility to transform expert knowledge into rules. This paper proposes an extended FMEA (eFMEA) as a systematic knowledge extraction tool to detail the failure causes of equipment in an industrial process. It outlines a methodology for the automatic generation of expert knowledge rules through the digitalisation of the eFMEA. The last contribution is a rule-based expert system for multi-fault detection and diagnosis based on the digitalised eFMEA. This expert system uses the communication protocol OPC-UA to feed the current data from the equipment, to detect faults. It can detect multi-faults simultaneously. The fault detection and diagnosis are visualised through a web application.",
      url: 'https://ieeexplore.ieee.org/document/8972299'
    },
    {
      id: 8,
      title: "A Cloud-based Architecture for Condition Monitoring based on Machine Learning",
      year: "2018",
      author: "F. Arévalo, M. R. Diprasetya and A. Schwung",
      abstract: "In the framework of the digitalization of the industry, there is an increasing trend to use machine learning techniques to assess condition monitoring, fault detection, and process optimization. Traditional approaches use a local Information Technology (IT) framework centralized in a server in order to provide these services. Cost of equipment and IT manpower are associated with the implementation of a condition monitoring based on machine learning. Nowadays, cloud computing can replace local IT frameworks with a remote service, which can be paid according to the customer needs. This paper proposes a cloud-based architecture for condition monitoring based on machine learning, which the end-user can assess through a web application. The condition monitoring is implemented using a fusion of classification methods. The fusion is implemented using Dempster-Shafer Evidence Theory (DSET). The results show that the use of DSET improves the overall result.",
      url: 'https://ieeexplore.ieee.org/document/8471970'
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020 - Present",
        position: "Research Assistant",
        company: "South Westphalia University of Applied Science, Soest, Germany",
        details: "Developing and maintaining Python library for Machine Learning (MLPro). Implemented path planning for welding robot. Developed a kinematic fused neural network for the forward and inverse kinematic of robot manipulators. Implemented a Condition Monitoring for a pump machine with RevolutionPi."
      },
      {
        id: 2,
        year: "2018 - 2019",
        position: "Research Assistant",
        company: "University of Applied Science, Bielefeld, Germany",
        details: "Designed a small IoT Factory. Simulated IoT Factory with Technomatix Plant Simulation. Implemented a web based machine learning for drawing recognition based on Tensorflow."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020 - Present",
        graduation: "PhD",
        university: "Promotionskolleg NRW, Germany",
        details: "Developing a transferable model based reinforcement learning for robot manipulators. Auto-Parameterization of DH parameter with Homogeneous Transformation Matrix Neural Network and Dual Quaternion Neural Network. Developing a differentiable dynamic parameter of robot manipulator based on Newton Euler Algorithm."
      },
      {
        id: 2,
        year: "2016 - 2017",
        graduation: "Master of Science",
        university: "South Westphalia University of Applied Science, Soest, Germany",
        details: "Developed a controller for magnetic suspension system with Feedback Linearization. Implemented path planning for mobile robot with Lidar sensor in Robot Operating System (ROS) environment. Developed a Anomaly Detection for Automation System in Cloud environment."
      },
      {
        id: 3,
        year: "2011 - 2015",
        graduation: "Bachelor of Engineering",
        university: "Swiss German University, Jakarta, Indonesia",
        details: "Developed a controller for a suspension system wit PID control in Matlab. Self-built drone with RaspberryPi."
      }
    ]
  },
  blogs: [
  ],
  contactInfo: {
    phoneNumbers: ['Email me'],
    emailAddress: ['personal@rizkydiprasetya.com'],
    address: "Lübecker Ring 2, 59494 Soest"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/publications").reply(config => {
  const response = database.publications;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});