import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Publication from "../components/Publication";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";

function Publications() {
  const [publication, setPublication] = useState([]);

  useEffect(() => {
    axios.get("/api/publications").then((response) => {
      setPublication(response.data);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>Publications - Mochammad Rizky Diprasetya</title>
        <meta
          name="description"
          content="Publications Mochammad Rizky Diprasetya"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-skills-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="Publications" />
            <div className="mi-publication-wrapper">
              {publication.map((publicationitem) => (
                <Publication key={publicationitem.id} publicationData={publicationitem} />
              ))}
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Publications;
