import React from "react";

function Publication(props) {
  const { title, year, author, abstract, url } = props.publicationData;
  return (
    <div className="mi-publication mt-30">
      <div className="mi-publication-summary">
        <h6 className="mi-publication-year">{year}</h6>
      </div>
      <div className="mi-publication-abstract">
        <h5>{title}</h5>
        <h6 className="mi-publication-author">{author}</h6>
        <p>{abstract}</p>
        {!url ? null : <h5>
          <a rel="noopener noreferrer" target="_blank" href={url}>
            See Publication
          </a>
        </h5>}
      </div>
    </div>
  );
};

export default Publication;
